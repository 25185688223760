import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Loader2 } from "lucide-react";
import { API } from "../../helpers";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { HiHome } from "react-icons/hi";
import { twMerge } from "tailwind-merge";
import Header from "@/components/Header";
import SideBar from "@/components/Sidebar";
import Swal from "sweetalert2";

async function profileCompany(dataRequest) {
  const user = JSON.parse(localStorage.getItem("user"));
  const accessToken = localStorage.getItem("accessToken");

  return fetch(`${API}/company/create-update/${user.company_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(dataRequest),
  }).then((data) => data.json());
}

export default function Profile() {
  const navigate = useNavigate();
  const [isActive, setActive] = useState(true);
  const handleSidebar = () => {
    setActive(!isActive);
  };
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [code, setCode] = useState();
  const [address, setAddress] = useState();
  const [province, setProvince] = useState();
  const [subProvince, setSubProvince] = useState();
  const [district, setDistrict] = useState();
  const [subDistrict, setSubDistrict] = useState();
  const [dataProvince, setDataProvince] = useState([]);
  const [dataSubProvince, setDataSubProvince] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubDistrict, setDataSubDistrict] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const accessToken = localStorage.getItem("accessToken");
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    setLoadingBtn(true);
    fetch(`${API}/company/detail/${user.company_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setName(data.data.name);
        setEmail(data.data.email);
        setPhone(data.data.phone);
        setCode(data.data.code);
        setAddress(data.data.address);
        setProvince(data.data.province);
        setSubProvince(data.data.sub_province);
        setDistrict(data.data.district);
        setSubDistrict(data.data.subDistrict);
      });

    fetch(`${API}/params/province`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setDataProvince(data.data);
      });

    setLoadingBtn(false);
  }, []);

  useEffect(() => {
    setLoadingBtn(true);
    fetch(`${API}/params/sub-province/${province}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setDataSubProvince(data.data);
      });

    setLoadingBtn(false);
  }, [province]);

  useEffect(() => {
    setLoadingBtn(true);
    fetch(`${API}/params/district/${subProvince}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setDataDistrict(data.data);
      });

    setLoadingBtn(false);
  }, [province, subProvince]);

  useEffect(() => {
    setLoadingBtn(true);
    fetch(`${API}/params/sub-district/${district}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setDataSubDistrict(data.data);
      });

    setLoadingBtn(false);
  }, [province, subProvince, district]);

  const handleSubmit = async (e) => {
    setLoadingBtn(true);
    e.preventDefault();
    const response = await profileCompany({
      name: name,
      email: email,
      phone: phone,
      code: code,
      address: address,
      province: province,
      sub_province: subProvince,
      district: district,
      sub_district: subDistrict,
    });

    if (response.code == 200) {
      Swal.fire({
        title: "Berhasil",
        text: response.message,
        icon: "success",
        buttons: false,
        timer: 2000,
      }).then((value) => {
        localStorage.setItem("user", JSON.stringify(response.data));
      });

      setLoadingBtn(false);
    } else {
      Swal.fire({
        title: "Error!",
        text: response.message,
        icon: "error",
      });

      setLoadingBtn(false);
    }
  };

  return (
    <div className="layout">
      <Header />
      <div className="flex-1 h-[900px] relative">
        <SideBar />
        <div className={twMerge("content-in", isActive && "active")}>
          <ScrollArea className="rounded-md scroll-sidebar">
            <div className="p-0">
              <div className="h-auto">
                <Label className="text-2xl font-bold title-main">
                  Profil Perusahaan
                </Label>
                <Breadcrumb>
                  <BreadcrumbList>
                    <BreadcrumbItem>
                      <BreadcrumbLink
                        href="/home"
                        className="flex gap-1 items-center"
                      >
                        <HiHome></HiHome> Beranda
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbPage>Profil Perusahaan</BreadcrumbPage>
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="h-auto pt-4">
                  <div className="grid grid-cols-12 gap-5">
                    <div className="col-span-12 h-full">
                      <Card className="w-full h-full pt-5">
                        <CardContent>
                          <div className="grid grid-cols-12 w-full items-center gap-4">
                            <div className="col-span-12">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="name">Nama Perusahaan *</Label>
                                <Input
                                  id="name"
                                  type="text"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-span-4">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="code">Kode Perusahaan *</Label>
                                <Input
                                  id="code"
                                  type="text"
                                  value={code}
                                  onChange={(e) => setCode(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-span-4">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="email">Email Perusahaan</Label>
                                <Input
                                  id="email"
                                  type="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-span-4">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="phone">Telp Perusahaan</Label>
                                <Input
                                  id="phone"
                                  type="text"
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-span-12">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="address">
                                  Alamat Perusahaan
                                </Label>
                                <Textarea
                                  id="address"
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                  placeholder="Ketik alamat perusahaan anda disini."
                                />
                              </div>
                            </div>
                            <div className="col-span-3">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="province">Propinsi</Label>
                                <Select
                                  id="province"
                                  onValueChange={setProvince}
                                  value={province}
                                >
                                  <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Pilih Propinsi" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      {dataProvince.map((province) => (
                                        <SelectItem value={province.province}>
                                          {province.province}
                                        </SelectItem>
                                      ))}
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              </div>
                            </div>
                            <div className="col-span-3">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="sub_province">
                                  Kota/Kabupaten *
                                </Label>
                                <Select
                                  id="sub_province"
                                  onValueChange={setSubProvince}
                                  value={subProvince}
                                >
                                  <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Pilih Kota/Kabupaten" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      {dataSubProvince.map((subprovince) => (
                                        <SelectItem
                                          value={subprovince.sub_province}
                                        >
                                          {subprovince.sub_province}
                                        </SelectItem>
                                      ))}
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              </div>
                            </div>
                            <div className="col-span-3">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="district">Kecamatan</Label>
                                <Select
                                  id="district"
                                  onValueChange={setDistrict}
                                  value={district}
                                >
                                  <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Pilih Kecamatan" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      {dataDistrict.map((district) => (
                                        <SelectItem value={district.district}>
                                          {district.district}
                                        </SelectItem>
                                      ))}
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              </div>
                            </div>
                            <div className="col-span-3">
                              <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="sub_district">
                                  Kelurahan/Desa
                                </Label>
                                <Select
                                  id="sub_district"
                                  onValueChange={setSubDistrict}
                                  value={subDistrict}
                                >
                                  <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Pilih Kelurahan/Desa" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      {dataSubDistrict.map((subDistrict) => (
                                        <SelectItem
                                          value={subDistrict.sub_district}
                                        >
                                          {subDistrict.sub_district}
                                        </SelectItem>
                                      ))}
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                        <CardFooter className="flex">
                          <div className="w-[500px]">
                            <Button
                              type="submit"
                              className="bg-[var(--color-primary)] w-full"
                              disabled={loadingBtn ? true : false}
                            >
                              {
                                <>
                                  {loadingBtn ? (
                                    <>
                                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                      <span>Loading...</span>
                                    </>
                                  ) : (
                                    <>
                                      <span>Simpan Data</span>
                                    </>
                                  )}
                                </>
                              }
                            </Button>
                          </div>
                        </CardFooter>
                      </Card>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
}
